export function BannerDataComponent (type, area, array) {
  
  let dataArray = Object.values(array.node.imagesetting.bannerSettingsGroup).find(item => item.fieldGroupName === area);
  dataArray = {...((({ fieldGroupName, ...rest }) => rest)(dataArray))};
  dataArray = Object.values(dataArray)
  dataArray = dataArray.filter(item => item.image !== null);
  
  // シャッフルアルゴリズムでランダムに並び替え
  for (let i = dataArray.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [dataArray[i], dataArray[j]] = [dataArray[j], dataArray[i]];
  }
  return dataArray;
}