import React, {useState} from "react";
import Layout from "@components/kaigai-fx/layout"
import FixedFoot from "@components/kaigai-fx/fixedFooter.js";
import parse from "html-react-parser"
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import LazyLoad from 'react-lazy-load';

// Import SVG
import icon_t_SVG1 from '@images/kaigai-fx/top_t_icon1.svg';
import icon_post_SVG3 from '@images/kaigai-fx/post_icon3.svg';

// コンポーネント呼び出し
import { SearchCategList } from "@components/kaigai-fx/search-categList.js"; // カテゴリ検索機能
import { WordSearch } from "@components/kaigai-fx/word-search.js";
import { ProfileContents } from "@components/kaigai-fx/profile.js";
import { SeoContents } from "@components/kaigai-fx/seo-data.js" // Seo情報設定（Title,description以外）
import { RelatedContents } from "@components/kaigai-fx/related-article.js"; // 関連記事取得・形成
import Animation from "@components/kaigai-fx/animation.js";
import { BannerDataComponent } from "@components/kaigai-fx/bannerDataComponent.js";
import { ArchiveVisualComponent } from "@components/kaigai-fx/archiveVisualComponent.js";


const FxTagsArchive = ({pageContext}) => {

  const indexUrl = '/kaigai-fx/';
  const name = pageContext.title // 該当タグ名
  const searchList = SearchCategList().Main; // カテゴリ検索リスト
  const wordSearchContentPC = WordSearch().PC; // ワード検索PC
  const wordSearchContentMB = WordSearch().MB; // ワード検索MB

   // バナー情報取得（初回レンダリング時のみ作動）
  const [bannerArray_side] = useState(() => BannerDataComponent('banner', 'banner_area_3', pageContext.bannerData));

  const setArray = pageContext.postArray; // ページ分割データ取得

  // サムネイル画像設定
  const thumData = ArchiveVisualComponent(setArray, 'thumbnail', pageContext.archiveVisualData);

  // Seo情報設定（Title,description以外）
  const pathName = 'tags';
  // page番号も渡す
  const seoTag = SeoContents({ Path:pathName, Title:name, Slug:pageContext.slug, PageNum: pageContext.pagenum, Date: setArray[0]?.node.date, Modified: setArray[0]?.node.modified });

  // タグ設定
  let tagArray = setArray.flatMap(item => item.node.fxTags.nodes.map(tag => ({ name: tag.name, slug: tag.slug }))); // 全タグ取得して配列化
  tagArray = Array.from(new Set(tagArray.map(tag => tag.slug))).map(slug => tagArray.find(tag => tag.slug === slug)); // 重複削除
  tagArray = tagArray.filter(item => item.name.indexOf('Q&A') === -1); // Q&Aタグ削除
  tagArray = tagArray.filter(item => item.slug !== pageContext.slug && item.slug !== 'display-on-top'); // 現在のタグとTOP表示タグを削除

  // 関連記事取得(components/related-article.js)
  const slugArray = setArray.map(item => item.node.slug);
  const relatedList = RelatedContents(pageContext, tagArray, slugArray, pageContext.archiveVisualData)

  // PCサイズタイトル文字数制限設定(テキスト, 制限文字数)
  function shortText(text, len) {
    let shortText;
    if ( text.length > len ) {shortText = text.substr( 0, len) + ' ...';} else {shortText = text;}
    return shortText
  }
  // プロフィール
  const profilePC = ProfileContents().PC 
  const profileMB = ProfileContents().MB

  return (
    <>
      <Layout>
        {seoTag}
        <div className="md:max-w-6xl md:flex justify-between mx-auto pt-5 md:pt-12"> 
          
          <div className="max-w-4xl pb-5 px-5 mb-10 md:pb-0 md:mb-0 w-full">
            
            {/* メインビジュアル */}
            <div className="text-left mb-3 mx-auto flex items-center">
              <LazyLoad className='' height={77.95} width={49.5} offset={300}>
                <img className="" alt="icon2" width="49.5" height="77.95" src={icon_t_SVG1}/>
              </LazyLoad>
              <h1 className="ml-5 font-semibold tracking-wide text-[24px] md:text-[56px] table" itemProp="headline">{ name }</h1>
            </div>

            <p className="text-[12px] mb-[20px] md:mb-[30px]">
              {/* パンくず */}
              <div className="flex mb-2 md:mb-4">
                <LazyLoad className='flex items-center' height={19.5} width={18.72} offset={300}>
                  <img className="pr-[3px] h-auto" width="18.72" height="19.5" alt="breadcrumb" src={icon_post_SVG3}/>
                </LazyLoad>
                <p className="px-[2px]">{name}</p>
              </div>

              {/* タグ */}
              <nav className="flex flex-wrap">
                {tagArray.map((result =>
                  <div className="mb-4">
                    <a href={`${indexUrl}tags/${result.slug}/`} className="mb-3 mr-2 text-[#fff] bg-[#3E3D3D] text-[13px] rounded px-2 py-1 pb-2 text-center">{result.name}</a>
                  </div>                    
                ))}
              </nav>           
            </p>

            {/* 記事一覧 */}
            <Animation customType='falling-item' loop='loop'>
              <ul className="mx-auto md:flex max-w-xl md:flex-wrap items-center md:max-w-5xl justify-start" style={{ listStyle: `none` }}>
                {setArray.map((result, index) => 
                  <li className="md:w-1/3 md:aspect-square" style={{marginBottom:15 ,padding:10}}>
                    <article className="post-list-item !m-0" style={{ height: '100%' }} itemScope itemType="http://schema.org/Article">
                      <a href={indexUrl + result.node.slug + "/"} itemProp="url" className={`${result.node.featuredImage? 'Campaign': 'Campaign2'} w-[100%]`}>
                        <div className="w-full aspect-[25/14]">
                          {/* 画像部分 */}
                          {result.node.featuredImage?
                            <GatsbyImage
                              image={getImage(result.node.featuredImage.node.localFile.childImageSharp.gatsbyImageData)} alt={result.node.title}
                              className='aspect-[25/14] h-full block w-full h-full rounded-blog overflow-hidden'>
                            </GatsbyImage>
                          :
                            <div className='relative overflow-hidden rounded-blog'>
                              <div className='absolute z-10 h-full w-full flex justify-center items-center'>
                                <p className={`relative ${thumData[index].textColor} ${thumData[index].fontSize.mb} ${thumData[index].fontSize.pc} ${thumData[index].fontSize.max} font-[900] text-center`} style={{top:`${thumData[index].position}px`}}>
                                  {thumData[index].text2 !== '' && thumData[index].text2 !== undefined?
                                    <p className='relative top-[6px] md:top-[6px] leading-[1.4]'>{thumData[index].text1}<br/>{thumData[index].text2}</p>
                                    :
                                    <>{thumData[index].text1}</>            
                                  }
                                </p>
                              </div>
                              <LazyLoad className='' offset={300}>
                                <img className="w-full object-cover aspect-[25/14]" alt={thumData[index].alt} src={thumData[index].img}/>
                              </LazyLoad>
                            </div>
                          }
                        </div>
                        {/* タイトル部分 */}
                        <div className="bg-white w-full text-left" >
                          <p className="hidden md:block CampP md:text-xs text-[15px] font-bold mb-4 py-2 text-left " style={{ height: '8%' }}>{shortText(result.node.title, 35)}</p>
                          <p className="md:hidden CampP md:text-xs text-[15px] font-bold mb-4 py-2 text-left " style={{ height: '8%' }}>{result.node.title}</p>
                          <p className="hidden md:block text-[12px] text-left md:text-[10px] pb-4">{parse(shortText(result.node.excerpt, 60))}</p>
                          <p className="md:hidden text-[12px] text-left md:text-[10px] pb-4">{parse(result.node.excerpt)}</p>
                        </div>
                      </a>
                    </article>
                  </li>
                )}
              </ul>
            </Animation>

            {/* ページネーション */}
            <div className='mt-[30px] flex justify-center'>
              {pageContext.array.map((result) => 
                <>
                  {(result.num === 1 || result.num === pageContext.maxnum || Math.abs(pageContext.pagenum - result.num) <= 2)? 
                    <div className={`${pageContext.pagenum === result.num? 'bg-[#36A7C3]': 'bg-[#D2D2D2]'} h-[36px] min-w-[36px] mx-[5px] hover:bg-[#36A7C3] transition-all duration-500 shadow-[3px_3px_3px_rgba(0,0,0,0.1)]`}>
                      <a className='h-full w-full text-white flex items-center justify-center' href={result.num === 1? `/kaigai-fx/tags/${pageContext.slug}/`: `/kaigai-fx/tags/${pageContext.slug}/${result.num}/`}>
                        {result.num}
                      </a>
                    </div>
                    :
                    Math.abs(pageContext.pagenum - result.num) === 3?
                      <p className='flex justify-center items-center text-[9px]'>・・・</p>
                      :
                      <></>
                  }
                </>  
              )}
            </div>

          </div>

          <aside className="column2_filed px-5 md:pt-0 pt-8">
            <div className="md:sticky">
              {/* バナー画像（横） */}
              {bannerArray_side?.map((result) => (
                <div className="md:mt-0 mt-5 mb-5">
                  <a className="" href={result.url} aria-label="banner" {...result.linktype !== '内部リンク' ? {target: '_blank', rel: 'noopener noreferrer'}: {}}>
                    <span className="hidden">{result.text}</span>
                    <div className="">
                      <GatsbyImage
                        image={getImage(result.image?.localFile?.childImageSharp?.gatsbyImageData)}
                        alt={result.title}
                      />
                    </div>
                  </a>
                </div>
              ))}

              {/*関連記事 */}
              <h3 className="mb-5 font-semibold flex ">関連記事</h3>
              <div className="flex-wrap justify-between mb-10 md:block flex">
                {relatedList}
              </div>
              
              <div className="mb-10">
                {/*カテゴリ検索（PC） */}
                {searchList}
                {/*PCワード検索 */}
                {wordSearchContentPC}
              </div>

              {/* PCプロフィール */}
              {profilePC}

            </div>
          </aside>
        </div>

        <div>
          {/*ワード検索 */}
          {wordSearchContentMB}
          
         {/* MBプロフィール */}
          {profileMB}
        </div>
        
        {/* 固定フッター */}
        <FixedFoot fixPattern='archive' item={SearchCategList().Sub} />
      </Layout>
    </>
  )
}
export default FxTagsArchive